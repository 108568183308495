import { Person } from '../interfaces/people.interface';

export const keyPeople: Person[] = [
  {
    pic: 'ms@1.jpg',
    name: 'Manjinder Sandhu',
    position: 'CEO & Founder',
    bio: `Manjinder is a Professor of Population Health and Data Sciences at Imperial College London. He trained at King's College London, the London School of Hygiene and Tropical Medicine, and the University of Cambridge. He was also senior group leader at the Wellcome Sanger Institute between 2009 and 2019.\n\nHis academic research focuses on creating and interpreting health and genetic data and integrating technological advances at the population health level. As Omnigen's Founder, his work now centres on integrating real-world data, including electronic health records, and genomic resources in Europe, the Middle East, Asia and Africa.`
  },
  {
    pic: 'ey@1.jpg',
    name: 'Liz Young',
    position: 'Research & Information Governance Manager',
    bio: `Liz qualified in Medicine at University College London Medical School. She completed her NHS specialist training in public health medicine, including a PhD in Genetic Epidemiology at the University of Cambridge. She worked at the Wellcome Sanger Institute between 2010 and 2019, focusing on large-scale population health and genomics research in low- and middle-income countries.\n\nAt Omnigen, she is responsible for research governance and ethics, information governance, and corporate compliance and regulation. She is Omnigen's Caldicott Guardian.`
  },
  {
    pic: 'sa.jpg',
    name: 'Sophie Allcock',
    position: 'Operations & Research Lead',
    bio: `Sophie has more than eight years' experience of working on commercial and academic research studies combined. She has a background in global health research with a PhD in Epidemiology from the University of Cambridge and a Masters in Medical Parasitology from the London School of Hygiene and Tropical Medicine.\n\nAs Operations & Research Lead, Sophie is responsible for the development and operational delivery of Omnigen's large-scale observational research studies, namely the Discover Me platform.`
  },
  {
    pic: 'lb@1.jpg',
    name: 'Louise Barr',
    position: 'Strategic Partnerships Lead',
    bio: `Louise has more than ten years' experience in commercial and academic research. She completed her PhD in Epidemiology at the University of Cambridge and a Masters in Public Health from London School of Hygiene and Tropical Medicine. Her research has primarily focussed on large-scale population health research in the African region. As part of this work, she has also been involved in capacity-building initiatives such as the African Partnership for Chronic Disease Research.\n\nAt Omnigen, she is responsible for managing strategic partnerships with clients and vendors to ensure the delivery of the Discover Me programme.`
  },
  {
    pic: 'mc.jpg',
    name: 'Mark Cullen',
    position: 'Head of Product & Technology',
    bio: `Mark has over 20 years of experience in user-centred design and development in various industries, from finance, logistics and e-commerce. Mark prides himself on harmonizing cutting-edge technology with human-centric design principles, resulting in innovative and intuitive digital products.\n\nAt Omnigen, Mark is responsible for defining the product strategy, setting the product vision, and ensuring the successful execution of product initiatives, including customer insights where possible to drive successful outcomes.`
  },
  {
    pic: 'js@1.jpg',
    name: 'Joelle Sandhu',
    position: 'Quality and Study Operations Consultant',
    bio: `Joelle has a degree in Pharmacology from Kings College London and a Masters in Clinical Research from Cardiff University. With a background of over 20 years in industry, Joelle has led international, cross functional teams across all phases of clinical trials and has comprehensive experience of planning, executing and reporting global studies. Joelle has expertise working to and developing industry standard Quality Management Systems.\n\nWorking in collaboration with consultants with extensive industry experience, Joelle is responsible for quality assurance and study operations on behalf of Omnigen.\n\nJoelle is a consultant employed by Trey Consulting Ltd where she is the Managing Director.`
  }
];

export default { keyPeople };
