import React, { ReactElement, ReactNode } from 'react';
import cx from 'classnames';

import './Strip.scss';

type StripVariant =
  | 'greyGrad'
  | 'primaryGrad'
  | 'greyGradRev'
  | 'grey'
  | 'white'
  | 'primary'
  | 'pattern';

export interface StripProps {
  borderBottom?: boolean;
  children: ReactElement | ReactNode;
  id: string;
  isSection?: boolean;
  minHeight?: number | null;
  padded?: boolean;
  variant?: StripVariant;
}

const Strip = ({
  borderBottom,
  children,
  id,
  isSection,
  minHeight,
  padded,
  variant
}: StripProps): ReactElement => {
  const CustomTag = isSection
    ? (`section` as keyof JSX.IntrinsicElements)
    : (`div` as keyof JSX.IntrinsicElements);

  return (
    <CustomTag
      className={cx('App-Strip', {
        'App-Strip--greyGrad': variant === 'greyGrad',
        'App-Strip--greyGradRev': variant === 'greyGradRev',
        'App-Strip--primaryGrad': variant === 'primaryGrad',
        'App-Strip--primary': variant === 'primary',
        'App-Strip--grey': variant === 'grey',
        'App-Strip--white': variant === 'white',
        'App-Strip--padded': padded,
        'App-Strip--borderBottom': borderBottom,
        'App-Strip--pattern': variant === 'pattern'
      })}
      id={id}
      style={{ minHeight: minHeight ? `${minHeight}px` : undefined }}
    >
      {children}
    </CustomTag>
  );
};

Strip.defaultProps = {
  borderBottom: false,
  isSection: false,
  minHeight: null,
  padded: false,
  variant: 'default'
};

export default Strip;
