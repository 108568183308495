import React, { ReactElement, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

import { EMAIL_DPO } from '../../shared/constants';

import './PrivacyDialog.scss';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Osano: any;
  }
}

export interface PrivacyDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const PrivacyDialog = ({ isOpen, onClose }: PrivacyDialogProps): ReactElement => {
  const { trackPageView } = useMatomo();
  useEffect(() => {
    if (isOpen) {
      trackPageView({
        href: 'privacy-notice'
      });
    }
  }, [isOpen, trackPageView]);
  const openCookies = () => {
    if (window.Osano) window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
  };
  return (
    <Dialog
      data-testid="privacy-dialog"
      fullScreen={false}
      open={isOpen}
      maxWidth="md"
      onClose={onClose}
      aria-labelledby="company-privacy-title"
    >
      <DialogContent className="App-privacy">
        <div className="App-privacy-title" id="company-privacy-title">
          <Typography variant="body1">Omnigen Biodata Ltd</Typography>
          <Typography variant="h5">Omnigen&apos;s Website Privacy Notice</Typography>
        </div>
        <Typography variant="body2" paragraph>
          This webpage sets out when and how we use your personal information that you or others
          provide to us.
        </Typography>
        <Typography variant="h6" paragraph>
          1. WHO WE ARE
        </Typography>
        <Typography variant="body2" paragraph>
          We are Omnigen Biodata Ltd, a private limited company (registered number 10565653).
        </Typography>
        <Typography variant="body2" paragraph>
          Our registered address is Four Station Square, Cambridge, CB1 2GE.
        </Typography>
        <Typography variant="body2" paragraph>
          We have appointed a data protection officer who is responsible for overseeing questions in
          relation to this privacy notice. If you have any questions about this privacy notice,
          including any requests to exercise your legal rights, please contact our data protection
          officer (Mishcon de Reya LLP) by email at <a href={`mailto:${EMAIL_DPO}`}>{EMAIL_DPO}</a>.
        </Typography>
        <Typography variant="h6" paragraph>
          2. HOW DO YOU USE MY DATA?
        </Typography>

        <Typography variant="body1" paragraph>
          When you phone us or interact with our website or our Platform (the study participant
          portal)
        </Typography>
        <Typography variant="body2" paragraph>
          When you phone us or contact us by email with general queries, we may also handle your
          personal information (your name, contact details and the other details you provide to us)
          in order to provide the customer services you have asked us to. This could be when you ask
          us to provide more information about what we do, the Platform which we have and use in the
          course of providing our services, or the studies which we are involved in, or to explain
          how our research is going or how we operate.
        </Typography>
        <Typography variant="body2" paragraph>
          We rely on your consent to handle your personal information in this way. If you do not
          provide us with the personal information we request from you for customer services
          purposes, we may not be able to fully answer your queries.
        </Typography>
        <Typography variant="body1" paragraph>
          When you have expressed an interest in our services
        </Typography>
        <Typography variant="body2" paragraph>
          When we send you marketing emails because you have opted-in to receive them, we rely on
          your consent to contact you for marketing purposes.
        </Typography>
        <Typography variant="body2" paragraph>
          Every email we send to you for marketing purposes will also contain instructions on how to
          unsubscribe from receiving them.
        </Typography>
        <Typography variant="body2" paragraph>
          You are not under any obligation to provide us with your personal data for marketing
          purposes.
        </Typography>
        <Typography variant="body2" paragraph>
          You can tell us that you do not want your personal information to be processed in this way
          at any time by contacting us at <a href={`mailto:${EMAIL_DPO}`}>{EMAIL_DPO}</a> or, where
          relevant, by following the unsubscribe link shown in every marketing communication you
          receive from us.
        </Typography>
        <Typography variant="body1" paragraph>
          To make our website better
        </Typography>
        <Typography variant="body2" paragraph>
          We will also use your personal information to provide you with a more effective user
          experience (such as by displaying content we think you will be interested in).
        </Typography>
        <Typography variant="body2" paragraph>
          Our use of your information in this way means that your experience of our website will be
          more tailored to you, and that the content you see on our site may differ from someone
          accessing the same site with a different history or browsing habits.
        </Typography>
        <Typography variant="body2" paragraph>
          We will also use your personal information for the purposes of making our site more
          secure, and to administer our site and for internal operations, including troubleshooting,
          data analysis, testing, research, statistical and survey purposes.
        </Typography>
        <Typography variant="body2" paragraph>
          We process your data for this reason because we have a legitimate interest to provide you
          with the best experience we can, and to ensure that our site is kept secure.
        </Typography>
        <Typography variant="body2" paragraph>
          You can prevent us from using your personal information in this way by using the &apos;do
          not track&apos; functionality in your internet browser. If you enable the &apos;do not
          track&apos; functionality, our site may be less tailored to your needs and preferences.
        </Typography>
        <Typography variant="body1" paragraph>
          If our business is sold
        </Typography>
        <Typography variant="body2" paragraph>
          We will transfer your personal information to a third party:
        </Typography>
        <ul>
          <Typography variant="body2" component="li">
            If we sell or buy any business or assets, we will provide your personal information to
            the buyer (but only to the extent we need to, and always in accordance with data
            protection legislation); or
          </Typography>
          <Typography variant="body2" component="li">
            If Omnigen Biodata Ltd or the majority of its assets are acquired by somebody else, in
            which case the personal information held by Omnigen Biodata Ltd will be transferred to
            the buyer.
          </Typography>
        </ul>
        <Typography variant="body2" paragraph>
          We process your personal information for this purpose because we have a legitimate
          interest to ensure our business can be continued by the buyer. If you object to our use of
          your personal information in this way, the buyer of our business may not be able to
          provide services to you.
        </Typography>
        <Typography variant="body2" paragraph>
          In some circumstances we may also need to share your personal information if we are under
          a duty to disclose or share it to comply with a legal obligation.
        </Typography>
        <Typography variant="h6" paragraph>
          3. WHAT ABOUT TECHNICAL INFORMATION AND ANALYTICS?
        </Typography>
        <Typography variant="body1" paragraph>
          Information we collect about you
        </Typography>
        <Typography variant="body2" paragraph>
          When you visit our website, we will automatically collect the following information:
        </Typography>
        <ul>
          <Typography variant="body2" component="li">
            Technical information, including the Internet Protocol (IP) address used to connect your
            computer to the internet, your login information, browser type and version, time zone
            setting, browser plug-in types and versions, screen resolution, operating system and
            platform; and
          </Typography>
          <Typography variant="body2" component="li">
            Information about your visit, including the full Uniform Resource Locators (URL),
            clickstream to, through and from our site (including date and time), page response
            times, download errors, length of visits to certain pages, page interaction information
            (such as scrolling, clicks, and mouse-overs) and methods used to browse away from the
            page.
          </Typography>
        </ul>
        <Typography variant="body1" paragraph>
          Information we receive from other sources
        </Typography>
        <Typography variant="body2" paragraph>
          We are also working closely with third party networks, analytics providers, hosting
          providers and search information providers from whom we may also receive general
          aggregated anonymous information about you.
        </Typography>
        <Typography variant="body2" paragraph>
          We will combine the information you provide to us with information we collect about you.
        </Typography>
        <Typography variant="h6" paragraph>
          4. INFORMATION ABOUT COOKIES AND OTHER TRACKING TECHNOLOGIES
        </Typography>
        <Typography variant="body2" paragraph>
          We use some cookies to make the website work, and we call these &apos;essential
          cookies&apos;. Our website also uses cookies to distinguish you from other users of our
          website. This helps us to provide you with a good experience when you browse our website
          and also helps us make improvements.
        </Typography>
        <Typography variant="body2" paragraph>
          A cookie is a small file of letters and numbers that we store on your browser or in your
          device&apos;s storage. We only use (and store) non-essential cookies if you provide your
          consent.
        </Typography>
        <Typography variant="body2" paragraph>
          You can block cookies by activating the setting on your browser that allows you to refuse
          the setting of all or some cookies. However, if you use your browser settings to block all
          cookies (including essential cookies) you may not be able to access all or parts of our
          website.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Types of cookies</strong>
        </Typography>
        <ul>
          <Typography variant="body2" component="li">
            Essential cookies. These are required for our website to function, and to allow visitors
            to use some of the features.
          </Typography>
          <Typography variant="body2" component="li">
            Analytics cookies. These allow us to understand how many people visit and how they use
            our website. This helps us to improve the website experience.
          </Typography>
          <Typography variant="body2" component="li">
            Functionality cookies. These allow us to recognise you when you return to our website
            and personalise content for you, for example by remembering your preferences.
          </Typography>
        </ul>
        <Typography variant="body2" paragraph>
          Visit our{' '}
          <Button onClick={openCookies}>
            <strong>Cookies table</strong>
          </Button>{' '}
          for details about the specific cookies Omnigen uses, their purpose and duration on our
          websites.
        </Typography>
        <Typography variant="h6" paragraph>
          5. WHERE ARE MY DATA STORED?
        </Typography>
        <Typography variant="body2" paragraph>
          We are based within the UK. Whenever we transfer your personal information outside of the
          UK and the EU, we ensure it receives additional protection as required by law. To keep
          this notice as short and easy to understand as possible, we haven&apos;t set out the
          specific circumstances when each of these protection measures are used. You can contact us
          at <a href="mailto: dpo@omnigenbiodata.co.uk">dpo@omnigenbiodata.co.uk</a> for more detail
          on this.
        </Typography>
        <Typography variant="body2" paragraph>
          All information you provide to us is stored on our secure servers or those of our third
          party data storage providers.
        </Typography>
        <Typography variant="h6" paragraph>
          6. HOW LONG DO WE RETAIN YOUR DATA FOR?
        </Typography>
        <Typography variant="body2" paragraph>
          In line with Omnigen&apos;s Records Retention Policy, we will only retain your personal
          information for as long as necessary to fulfil the purposes we collected it for, including
          for the purposes of satisfying any accounting or legal requirements. If you are a study
          participant, please also refer to the privacy notice for study participants{' '}
          <a href="https://discovermestudy.com">https://discovermestudy.com</a> for more
          information.
        </Typography>
        <Typography variant="body2" paragraph>
          To determine the appropriate retention period for the personal information we hold, we
          consider the amount, nature and sensitivity of the personal information, the risk of harm
          from unauthorised use or disclosure of your personal information, the reasons why we
          handle your personal information and whether we can achieve those purposes through other
          means, and the applicable legal requirements.
        </Typography>
        <Typography variant="h6" paragraph>
          7. WHAT ARE MY RIGHTS UNDER DATA PROTECTION LAWS?
        </Typography>
        <ul>
          <Typography variant="body2" component="li">
            <strong>Request access </strong>
            to your data (commonly known as a &quot;subject access request&quot;). This enables you
            to receive a copy of the data we hold about you and to check that we are lawfully
            processing it.
          </Typography>
        </ul>
        <ul>
          <Typography variant="body2" component="li">
            <strong>Request correction </strong>
            of the data that we hold about you. This enables you to have any incomplete or
            inaccurate information we hold about you corrected.
          </Typography>
        </ul>
        <ul>
          <Typography variant="body2" component="li">
            <strong>Request erasure </strong>
            of your data. This enables you to ask us to delete or remove data where there is no good
            reason for us continuing to process it. You also have the right to ask us to delete or
            remove your data where you have exercised your right to object to processing (see
            below).
          </Typography>
        </ul>
        <ul>
          <Typography variant="body2" component="li">
            <strong>Object to processing </strong>
            of your data where we are relying on a legitimate interest (or those of a third party)
            and there is something about your particular situation which makes you want to object to
            processing on this ground. You also have the right to object where we are processing
            your data for direct marketing purposes, research or statistical purposes.
          </Typography>
        </ul>
        <ul>
          <Typography variant="body2" component="li">
            <strong>Request the restriction </strong>
            of processing of your data. This enables you to ask us to suspend the processing of data
            about you, for example if you want us to establish its accuracy or the reason for
            processing it.
          </Typography>
        </ul>
        <ul>
          <Typography variant="body2" component="li">
            <strong>Request the transfer </strong>
            of your data to another party.
          </Typography>
        </ul>
        <Typography variant="body2" paragraph>
          Privacy law is often complicated, and whether these rights are available to you sometimes
          depends on the types of data we are handling, as well as why we are handling them. If you
          would like to exercise any of these rights, please contact us using the details at the
          bottom of this notice. You always have the right to lodge a complaint with us or the
          Information Commissioner&apos;s Office, the supervisory authority for data protection
          issues in England and Wales.
        </Typography>
        <Typography variant="h6" paragraph>
          8. WHAT ABOUT WEBSITES WE LINK TO?
        </Typography>
        <Typography variant="body2" paragraph>
          Our site may, from time to time, contain links to and from the websites of our partner
          networks and affiliates.
        </Typography>
        <Typography variant="body2" paragraph>
          Our site connects you to different websites. If you follow a link to any of these websites
          or use our services, please note that you have left our site and these websites have their
          own privacy notices.
        </Typography>
        <Typography variant="body2" paragraph>
          We do not accept any responsibility or liability for these notices or websites. Please
          check their notices before you submit any personal information to these websites.
        </Typography>
        <Typography variant="h6" paragraph>
          9. WHEN WILL YOU CHANGE YOUR PRIVACY NOTICE?
        </Typography>
        <Typography variant="body2" paragraph>
          Any changes we make to our privacy notice in the future will be posted on this page and,
          where appropriate, notified to you by e-mail or post.
        </Typography>
        <Typography variant="body2" paragraph>
          Please check back frequently to see any updates or changes to our privacy notice.
        </Typography>
        <Typography variant="h6" paragraph>
          10. HOW DO I CONTACT YOU WITH FEEDBACK?
        </Typography>
        <Typography variant="body2" paragraph>
          Questions, comments and requests regarding this privacy notice are welcomed and should be
          addressed to <a href={`mailto:${EMAIL_DPO}`}>{EMAIL_DPO}</a>.
        </Typography>
        <Typography variant="body2" paragraph>
          This privacy notice was last updated in July 2023.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary" data-testid="privacy-close">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrivacyDialog;
