import React, { ReactElement, useState, useEffect } from 'react';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

import { Container } from '@material-ui/core';
import NavList from '../NavList';
import SideNavList from '../SideNavList';
import SidePanel from '../SidePanel';

import { MenuItem } from '../../shared/interfaces/menuitem.interface';
import logo from '../../assets/logo/OmnigenFullColour.svg';
import { Language } from '../../shared/interfaces/language.type';
import './Header.scss';

export interface HeaderProps {
  companyName: string;
  menuItems?: MenuItem[];
  allowLanguageSelect?: boolean;
}

const Header = ({
  companyName,
  menuItems,
  allowLanguageSelect = false
}: HeaderProps): ReactElement => {
  const { i18n } = useTranslation();
  const { search } = useLocation();
  const { trackEvent } = useMatomo();
  const history = useHistory();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [languageChosen, setLanguageChosen] = useState<Language | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleNav = (to: string) => {
    trackEvent({ category: 'nav', action: to });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageSelect = (lang: string) => {
    localStorage.setItem('lang', lang);
    setLanguageChosen(lang as Language);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const query = new URLSearchParams(search);

    if (allowLanguageSelect) {
      if (query.get('lang')) {
        const queryLang = query.get('lang');
        handleLanguageSelect(queryLang as string);
        history.push('/dmsa');
      }

      if (localStorage.getItem('lang')) {
        setLanguageChosen(localStorage.getItem('lang') as Language);
        i18n.changeLanguage(localStorage.getItem('lang') as Language);
      }
    }
  }, [i18n, languageChosen, allowLanguageSelect, search, history]);

  return (
    <>
      {menuItems && (
        <Hidden mdUp>
          <SidePanel
            isOpen={isDrawerOpen}
            onClose={() => {
              setDrawerOpen(false);
            }}
          >
            <SideNavList
              menuItems={menuItems}
              onClick={() => setDrawerOpen(false)}
              handleNav={handleNav}
            />
          </SidePanel>
        </Hidden>
      )}
      <header className="App-header">
        <Container>
          <a className="App-logo-link" href="/">
            <img src={logo} alt={companyName} />
          </a>
          {menuItems && (
            <Hidden mdUp>
              <IconButton
                className="App-nav-hamburger"
                aria-label="Open Menu"
                data-testid="open-menu"
                onClick={() => {
                  setDrawerOpen(!isDrawerOpen);
                }}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          )}
          {menuItems && (
            <Hidden smDown>
              <NavList onClick={handleNav} menuItems={menuItems} />
            </Hidden>
          )}
          {allowLanguageSelect && (
            <span className="App-nav-lang">
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
              >
                {i18n.language === Language.EN && <>EN</>}
                {i18n.language === Language.ZU && <>ZU</>}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MuiMenuItem
                  onClick={() => {
                    handleLanguageSelect(Language.EN);
                  }}
                >
                  English
                </MuiMenuItem>
                <MuiMenuItem
                  onClick={() => {
                    handleLanguageSelect(Language.ZU);
                  }}
                >
                  Zulu
                </MuiMenuItem>
              </Menu>
            </span>
          )}
        </Container>
      </header>
    </>
  );
};

export default Header;
