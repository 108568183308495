import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

export const ActionButton = withStyles(() => ({
  root: {
    color: '#ffffff',
    backgroundColor: '#103544',
    border: '1px solid #103544',
    borderRadius: '2px',
    transition: 'all ease-in 0.2s',
    textDecoration: 'none !important',
    boxShadow: '0 0 0 2px rgba(16, 53, 68,0.4)',
    textTransform: 'uppercase',
    letterSpacing: 'initial',
    fontWeight: 300,
    fontSize: '1.1rem',
    '&:hover': {
      backgroundColor: '#103544',
      boxShadow: '0 0 0 7px rgba(16, 53, 68,0.2)'
    },
    minWidth: 300
  }
}))(Button);

export const WhiteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#ffffff'),
    backgroundColor: '#ffffff',
    border: '1px solid #103544',
    borderRadius: '2px',
    transition: 'all ease-in 0.2s',
    boxShadow: '0 0 0 2px rgba(93,93,93,0.1)',
    textTransform: 'uppercase',
    letterSpacing: 'initial',
    fontWeight: 300,
    fontSize: '1.1rem',
    '&:hover': {
      backgroundColor: '#dddddd',
      boxShadow: '0 0 0 7px rgba(93,93,93,0.06)'
    },
    minWidth: 300
  }
}))(Button);

export const CloseButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#ffffff'),
    backgroundColor: '#ffffff',
    border: '1px solid #103544',
    borderRadius: '2px',
    transition: 'all ease-in 0.2s',
    boxShadow: '0 0 0 2px rgba(93,93,93,0.1)',
    textTransform: 'uppercase',
    letterSpacing: 'initial',
    fontWeight: 300,
    fontSize: '1.1rem',
    '&:hover': {
      backgroundColor: '#dddddd',
      boxShadow: '0 0 0 7px rgba(93,93,93,0.06)'
    }
  }
}))(Button);

export const GhostButton = withStyles(() => ({
  root: {
    color: '#ffffff',
    backgroundColor: 'none',
    border: '1px solid #fff',
    borderRadius: '2px',
    transition: 'all ease-in 0.2s',
    textTransform: 'uppercase',
    letterSpacing: 'initial',
    fontWeight: 300,
    fontSize: '1.1rem',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.3)'
    },
    minWidth: 300
  }
}))(Button);

export const LinkInButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#0078BA'),
    backgroundColor: '#0078BA',
    border: '1px solid #0078BA',
    transition: 'all ease-in 0.2s',
    borderRadius: '7px',
    boxShadow: '0 0 0 2px rgba(0,120,186,0.3)',
    textTransform: 'none',
    fontWeight: 300,
    fontSize: '1.1rem',
    '&:hover': {
      backgroundColor: '#005E91',
      boxShadow: '0 0 0 7px rgba(0,120,186,0.1)'
    }
  }
}))(Button);

export default { LinkInButton, ActionButton, WhiteButton };
