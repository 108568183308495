import React, { ReactElement } from 'react';
import Typography from '@material-ui/core/Typography';
import './KeyValue.scss';

export interface KeyValueProps {
  title: string;
  icon?: () => ReactElement;
  blurb: string;
}

const KeyValue = ({ title, icon, blurb }: KeyValueProps): ReactElement => (
  <div className="App-KeyValue">
    {icon && <span className="App-KeyValue-icon">{icon()}</span>}

    <Typography variant="h4" component="h2" color="inherit" gutterBottom>
      {title}
    </Typography>
    <Typography variant="body1" component="p" color="inherit">
      {blurb}
    </Typography>
  </div>
);

KeyValue.defaultProps = {
  icon: () => undefined
};

export default KeyValue;
