import React, { ReactElement } from 'react';
import { Link } from 'react-scroll';
import { MenuItem } from '../../shared/interfaces/menuitem.interface';
import './SideNavList.scss';

export interface SideNavListProps {
  menuItems: MenuItem[];
  onClick: () => void;
  handleNav: (to: string) => void;
}

const SideNavList = ({ menuItems, onClick, handleNav }: SideNavListProps): ReactElement => {
  const handleClick = (to: string) => () => {
    handleNav(to);
    onClick();
  };
  return (
    <ul className="App-side-nav">
      {menuItems.map((item) => (
        <li key={`mob-nav-${item.to}`}>
          <Link
            activeClass="active"
            className="App-side-nav-link"
            data-testid="side-nav-item"
            to={item.to}
            spy
            smooth
            duration={500}
            offset={-105}
            onClick={handleClick(item.to)}
          >
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  );
};

SideNavList.defaultProps = {
  onClick: () => undefined
};

export default SideNavList;
