import React, { ReactElement } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Launch from '@material-ui/icons/Launch';
import Strip from '../components/Strip';
import { ActionButton } from '../components/Buttons';
import ParallaxContainer from '../components/ParallaxContainer';
import { DISCOVER_ME_UK_URL, DISCOVER_ME_SA_URL } from '../shared/constants';
import discoverMeLogo from '../assets/logo/DMFullColourUKTag.svg';
import discoverMeSALogo from '../assets/DMFullColourSA.svg';
import discoverMeUKLogo from '../assets/DMFullColourUK.svg';

const DmIntroContainer = (): ReactElement => (
  <Strip isSection id="discover" variant="white">
    <ParallaxContainer alt="Discover Me" id="paralax1" image="/parallax/strip.jpg">
      <img src={discoverMeLogo} alt="discoverMeLogo" className="App-centered-logo" />
    </ParallaxContainer>
    <Strip padded id="dmRegions">
      <Container>
        <Grid container spacing={4}>
          <Grid item sm={12} md={12}>
            <Box textAlign={{ xs: 'center', md: 'center' }} mb={4}>
              <Typography gutterBottom variant="h3" color="primary" className="App-text--light">
                <strong className="App-text--bolder">Discover Me</strong> Platform
              </Typography>
              <Typography paragraph variant="body1" color="textSecondary">
                <strong>Join our global research community today.</strong>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={4}>
          <Grid item sm={12} md={6}>
            <Box textAlign={{ xs: 'center', md: 'left' }}>
              <Box mb={2}>
                <img src={discoverMeUKLogo} width="350" alt="United Kingdom" />
              </Box>
              <Typography paragraph variant="subtitle2" color="textSecondary">
                Discover Me is a nationwide research study looking at how health and genetic
                information can allow us to better understand disease.
              </Typography>
              <Typography paragraph variant="body2" color="textSecondary">
                By collecting this information from 100,000s of people, Discover Me aims to help us
                find out why diseases may affect some people more than others. This could help
                transform healthcare.
              </Typography>
              <Typography paragraph variant="body2" color="textSecondary">
                All participants in the Discover Me UK study are able to receive health insights and
                their genetic ancestry profile.
              </Typography>
              <Typography paragraph variant="body2" color="textSecondary">
                Register your interest in the study and contribute to the future of healthcare
                research today.
              </Typography>

              <Hidden mdUp>
                <a
                  href={DISCOVER_ME_UK_URL}
                  target="_blank"
                  rel="noreferrer"
                  className="App-plain-link"
                >
                  <ActionButton
                    variant="contained"
                    color="secondary"
                    size="large"
                    type="button"
                    endIcon={<Launch />}
                  >
                    Discover Me UK
                  </ActionButton>
                </a>
              </Hidden>
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box textAlign={{ xs: 'center', md: 'left' }}>
              <Box mb={2}>
                <img src={discoverMeSALogo} width="350" alt="South Africa" />
              </Box>
              <Typography paragraph variant="subtitle2" color="textSecondary">
                Omnigen is committed to building a discovery science program that is globally
                representative—enabling discovery, supporting communities, and building research
                capacity.
              </Typography>
              <Typography paragraph variant="body2" color="textSecondary">
                In collaboration with the University of KwaZulu-Natal and Inkosi Albert Luthuli
                Central Hospital and the KwaZulu-Natal Department of Health, the Discover Me South
                Africa study aims to recruit up to 100,000 individuals, and integrate their
                electronic health record and genetic data to help understand disease risk and
                progression, and improve patient care and management. All participants will receive
                access to their health data and become part of our global research community.
              </Typography>
              <Hidden mdUp>
                <a
                  href={DISCOVER_ME_SA_URL}
                  target="_blank"
                  rel="noreferrer"
                  className="App-plain-link"
                >
                  <ActionButton
                    variant="contained"
                    color="secondary"
                    size="large"
                    type="button"
                    endIcon={<Launch />}
                  >
                    Discover Me South Africa
                  </ActionButton>
                </a>
              </Hidden>
            </Box>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid container spacing={4}>
            <Grid item sm={12} md={6}>
              <a
                href={DISCOVER_ME_UK_URL}
                target="_blank"
                rel="noreferrer"
                className="App-plain-link"
              >
                <ActionButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  type="button"
                  endIcon={<Launch />}
                >
                  Discover Me UK
                </ActionButton>
              </a>
            </Grid>
            <Grid item sm={12} md={6}>
              <a
                href={DISCOVER_ME_SA_URL}
                target="_blank"
                rel="noreferrer"
                className="App-plain-link"
              >
                <ActionButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  type="button"
                  endIcon={<Launch />}
                >
                  Discover Me South Africa
                </ActionButton>
              </a>
            </Grid>
          </Grid>
        </Hidden>
      </Container>
    </Strip>
  </Strip>
);

export default DmIntroContainer;
