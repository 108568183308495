import React, { ReactElement, ReactNode } from 'react';
import { Parallax } from 'react-parallax';
import './ParallaxContainer.scss';

export interface ParallaxContainerProps {
  alt: string;
  children?: ReactNode | null;
  id: string;
  image: string;
}

const ParallaxContainer = ({ alt, children, id, image }: ParallaxContainerProps): ReactElement => (
  <Parallax bgImage={image} bgImageAlt={alt} strength={200}>
    <div className="App-ParallaxContainer" id={id}>
      {children}
    </div>
  </Parallax>
);

ParallaxContainer.defaultProps = {
  children: null
};

export default ParallaxContainer;
