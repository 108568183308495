import React, { ReactElement } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import './Feature.scss';
import { GhostButton } from '../Buttons';
import { DISCOVER_ME_URL } from '../../shared/constants';

const Feature = (): ReactElement => (
  <div className="App-Feature" id="feature">
    <div className="App-Feature-main">
      <Container>
        <Grid container spacing={0}>
          <Grid item xs={12} md={9}>
            <Box textAlign={{ xs: 'center' }}>
              <Typography variant="h3" component="h3" color="inherit">
                <span className="App-text--light">transforming</span>{' '}
                <span className="App-text--bold">healthcare</span>{' '}
                <span className="App-text--bolder">together</span>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Hidden mdUp>
              <Box mt={4}> </Box>
            </Hidden>
            <Typography variant="h3" component="h3" color="inherit">
              <GhostButton
                data-testid="feature-button"
                size="large"
                href={DISCOVER_ME_URL}
                fullWidth
              >
                Discover More
              </GhostButton>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  </div>
);

export default Feature;
