import { createContext, useContext } from 'react';

export interface GlobalContextType {
  openGlobalPrivacyDialog: () => void;
}

const defaultGlobalConext: GlobalContextType = {
  openGlobalPrivacyDialog: () => undefined
};

const GlobalContext = createContext(defaultGlobalConext);

export const useGlobal = (): GlobalContextType => useContext(GlobalContext);

export default GlobalContext;
