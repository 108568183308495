import React, { ReactElement, ReactNode, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PrivacyDialog from '../../components/PrivacyDialog';
import { CONTACT_ADDRESS, COMPANY_NAME, COMPANY_TELEPHONE } from '../../shared/constants';
import { MenuItem } from '../../shared/interfaces/menuitem.interface';
import GlobalContext from '../context/GlobalContext';
import Feature from '../../components/Feature';

export const MENU_ITEMS: MenuItem[] = [
  { label: 'About', to: 'company' },
  { label: 'News', to: 'news' },
  { label: 'Discover Me', to: 'discover' },
  { label: 'Team', to: 'about' },
  { label: 'Careers', to: 'careers' },
  { label: 'Contact', to: 'contact' }
];

interface DmsaLayoutProps {
  children: ReactElement | ReactElement[] | ReactNode;
  // eslint-disable-next-line react/require-default-props
  splash?: boolean;
}

function MainLayout({ children, splash = true }: DmsaLayoutProps): ReactElement {
  const [isPrivacyOpen, setPrivacyOpen] = useState(false);
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    if (query.get('privacy') === 'true') {
      setPrivacyOpen(true);
    }
  }, [search]);

  return (
    <GlobalContext.Provider
      value={{
        openGlobalPrivacyDialog: () => {
          setPrivacyOpen(true);
        }
      }}
    >
      <div className={cx({ App: splash })}>
        <Header companyName={COMPANY_NAME} menuItems={MENU_ITEMS} />
        {children}
        <Feature />
        <Footer
          address={CONTACT_ADDRESS}
          companyName={COMPANY_NAME}
          telephone={COMPANY_TELEPHONE}
          onPrivacyOpen={() => {
            setPrivacyOpen(true);
          }}
        />
        <PrivacyDialog
          isOpen={isPrivacyOpen}
          onClose={() => {
            setPrivacyOpen(false);
          }}
        />
      </div>
    </GlobalContext.Provider>
  );
}

export default MainLayout;
