import React, { CSSProperties, ReactElement } from 'react';
import cx from 'classnames';
import './Image.scss';

interface ImageProps {
  alt: string;
  bordered?: boolean;
  framed?: boolean;
  src: string;
  elevation?: number;
  className?: string | null;
  rounded?: boolean;
  style?: CSSProperties;
}

const Image = ({
  alt,
  bordered,
  className,
  elevation,
  framed,
  rounded,
  src,
  style
}: ImageProps): ReactElement => (
  <img
    className={cx(
      'App-img',
      {
        'App-img--elevation-1': elevation === 1,
        'App-img--bordered': bordered,
        'App-img--framed': framed,
        'App-img--rounded': rounded
      },
      className
    )}
    style={style}
    src={src}
    alt={alt}
  />
);

Image.defaultProps = {
  bordered: false,
  elevation: 0,
  framed: false,
  className: null,
  rounded: false,
  style: undefined
};

export default Image;
