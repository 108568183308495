import React, { ReactElement } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Image from '../Image';

import './Profile.scss';

export interface ProfileProps {
  bio: string;
  name: string;
  pic?: string | null;
  position?: string | null;
}

const Profile = ({ bio, name, pic, position }: ProfileProps): ReactElement => (
  <div className="App-Profile">
    {pic && (
      <span className="App-Profile-pic">
        <Image framed src={pic} alt={name} className="App-Profile-thumb" elevation={1} />
      </span>
    )}
    <Box className="App-Profile-text" textAlign={{ xs: 'center', md: 'left' }}>
      <Typography variant="h4" component="h3" color="inherit">
        {name}
      </Typography>
      {position && (
        <Typography variant="subtitle1" component="h4" color="primary" gutterBottom>
          {position}
        </Typography>
      )}
      <Typography variant="body2" color="textPrimary" paragraph>
        {bio.split('\n').map((para, index) => (
          <React.Fragment key={`${name}-para-${index.toString()}`}>
            {para}
            <br />
          </React.Fragment>
        ))}
      </Typography>
    </Box>
  </div>
);

Profile.defaultProps = {
  pic: null,
  position: null
};

export default Profile;
