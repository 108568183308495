import React, { ReactElement, ReactNode } from 'react';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import './SidePanel.scss';

export interface SidePanelProps {
  children: ReactElement | ReactNode;
  isOpen?: boolean;
  onClose: () => void;
}

const SidePanel = ({ children, isOpen, onClose }: SidePanelProps): ReactElement => {
  return (
    <Drawer anchor="left" data-testid="side-nav" open={isOpen} onClose={onClose}>
      <Box padding={2}>
        <IconButton
          className="App-side-nav-close"
          aria-label="Close Menu"
          data-testid="close-menu"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        {children}
      </Box>
    </Drawer>
  );
};

SidePanel.defaultProps = { isOpen: false };

export default SidePanel;
